import React from 'react'
import { Breadcrumb } from 'ui'
import { Button, Heading, Hidden, HStack, Icon, VStack } from 'native-base'
import { Text, Box, Spinner } from 'ui'
import { MaterialIcons } from '@expo/vector-icons'
import { useNavigate } from '../../router'
import { ForceColorMode } from 'ui/ui-provider'

interface HeaderProps {
  title: string | React.ReactElement
  breadcrumbs?: { label: string; path?: string }[]
  onBack?: () => void
  renderAction: React.ReactElement | null
  leftAction?: React.ReactElement | null
  loading?: boolean
}

export default function PageHeader({
  title,
  breadcrumbs,
  renderAction,
  leftAction = null,
  onBack,
  loading,
}: HeaderProps) {
  const navigate = useNavigate()
  return (
    <ForceColorMode mode="dark">
      <HStack
        alignItems="center"
        justifyContent="space-between"
        borderBottomWidth="1"
        bg="base.800"
        // _light={{ borderBottomColor: 'base.200' }}
        _dark={{ borderBottomColor: 'base.700' }}
        borderBottomStyle="solid"
        px="4"
        py="0"
        minHeight="76px">
        {leftAction}
        <VStack flex={1}>
          {breadcrumbs && (
            <Hidden till="md">
              <Breadcrumb>
                {/* <Breadcrumb.Item label="Home" path="/" /> */}
                {breadcrumbs.map((bc, index) =>
                  bc.path ? (
                    <Breadcrumb.Item key={index} label={bc.label} path={bc.path} />
                  ) : (
                    <Breadcrumb.Item key={index} label={bc.label} />
                  )
                )}
              </Breadcrumb>
            </Hidden>
          )}
          <HStack alignItems="center" space="2">
            <Hidden from="md">
              <Button
                variant="ghost"
                size="sm"
                colorScheme="base"
                onPress={onBack || (() => navigate(-1))}
                leftIcon={<Icon as={MaterialIcons} size="6" name="west" color="base.500" />}
              />
            </Hidden>
            <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="bold" lineHeight="sm" numberOfLines={1}>
              {title}
            </Text>
          </HStack>
        </VStack>

        <HStack pr={{ base: 6, lg: 0 }}>
          {loading && <Spinner color="base.400" mr={1} />}
          {renderAction}
        </HStack>
      </HStack>
    </ForceColorMode>
  )
}
