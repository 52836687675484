import React from 'react'
import CalendarPicker from 'react-native-calendar-picker'
import { FontAwesome5, MaterialIcons } from '@expo/vector-icons'
import { Icon, useToken, useColorModeValue, IButtonProps, Popover } from 'native-base'
import { Button } from 'ui'

interface DateProps {
  [key: string]: any
}

type Props = IButtonProps & DateProps

const DatePickerPopover = ({ buttonProps, popoverProps, ...props }: Props) => {
  const [textColor, disableDatesTextColor, navIconColor] = useToken('colors', [
    useColorModeValue('base.800', 'base.50'),
    useColorModeValue('base.400', 'base.500'),
    useColorModeValue('base.800', 'base.50'),
  ])

  const baseProps = {
    allowRangeSelection: false,
    previousComponent: <Icon size={[4, 8]} as={MaterialIcons} name="navigate-before" color={navIconColor} />,
    nextComponent: <Icon size={[4, 8]} as={MaterialIcons} name="navigate-next" color={navIconColor} />,
    width: 300,
    textStyle: {
      color: textColor,
    },
    disabledDatesTextStyle: {
      color: disableDatesTextColor,
    },
  }

  const allProps = { ...baseProps, ...props }

  return (
    <Popover
      {...popoverProps}
      trigger={(triggerProps) => {
        return (
          <Button
            {...triggerProps}
            leftIcon={<Icon as={FontAwesome5} name="calendar-alt" size={4} />}
            {...buttonProps}
            onPress={(e) => {
              triggerProps.onPress && triggerProps.onPress(e)
              buttonProps && buttonProps.onPress && buttonProps.onPress(e)
            }}></Button>
        )
      }}>
      <Popover.Content w={80}>
        <Popover.Arrow />
        <Popover.Body>
          <CalendarPicker {...allProps} />
        </Popover.Body>
      </Popover.Content>
    </Popover>
  )
}

export default DatePickerPopover
