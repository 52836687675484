import React, { useEffect, useState, useTransition } from 'react'
import { RegisterOptions, useFormContext, Controller, FieldErrors } from 'react-hook-form'
import {
  FormControl,
  Input as NInput,
  Select as NSelect,
  TextArea as NTextArea,
  Radio as NRadio,
  Checkbox as NCheckbox,
  ISelectProps,
  ChevronDownIcon,
  IInputProps,
  Text,
} from 'native-base'
import './forms-styles.css'
import AsyncSelect, { AsyncCreatableSelect } from './async-select'
import { Box, Small } from 'ui'
import useDebounce from 'ui/hooks/use-debounce'
import * as R from 'ramda'
import { GuardDebug, useUISecurity } from 'ui/ui-security'

export interface ControlledFieldProps {
  name: string
  description?: string
  label?: string
  labelPosition?: 'top' | 'left'
  errorLabel?: string
  rules?: RegisterOptions
  isRequired?: boolean
  isDisabled?: boolean
  toModel?: (data: string) => any
  toUI?: (data: any) => string
  skey?: string
  [key: string]: any
}

function identity<F>(x: F): F {
  return x
}

export function log(label: string, ...value: any) {
  console.log(label, value)
  return value[value.length - 1]
}

// PG: 09/12 selectedValue was added for compatibily with Select tag
export const ControlledField = <P extends object>(Child: React.FC<P>, defaultChildProps?: Partial<P>) =>
  React.forwardRef((props: P & ControlledFieldProps, ref) => {
    const {
      control,
      formState: { errors },
      getFieldState,
    } = useFormContext()
    const uiSecurity = useUISecurity()

    defaultChildProps = defaultChildProps || {}

    let validationRules = { ...(props.rules || {}) }

    if (props.isRequired) {
      validationRules.required = { value: true, message: `${props.errorLabel || props.label} is required` }
    }

    const { name, label, labelPosition = 'top', rules, isRequired, flex, defaultValue, toModel, toUI, ...rest } = props

    const convertToModel = toModel || identity
    const convertToUI = toUI || identity

    const { error, invalid } = getFieldState(name)

    const canChange = uiSecurity.canChange(props.skey)

    const isDisabled = rest.isDisabled ?? !canChange
    const isVertical = labelPosition === 'top'

    return (
      <FormControl
        {...{ isRequired, isDisabled }}
        isInvalid={invalid}
        flex={isVertical ? flex : undefined}
        flexDirection={isVertical ? 'column' : 'row'}
        testID={`field_wrapper-${name}`}>
        {!!label && <FormControl.Label>{label}</FormControl.Label>}
        <Box flex={1} ml={isVertical ? 0 : 2}>
          <Controller
            control={control}
            render={({ field }) => (
              <GuardDebug skey={props.skey}>
                <Child
                  {...defaultChildProps}
                  {...{ isDisabled }}
                  {...field}
                  testID={`field-${name}`}
                  value={convertToUI(field.value)}
                  onChange={(data: any) => {
                    // console.log('Field onChange', field.value, data)
                    return canChange ? field.onChange(convertToModel(data)) : null
                  }}
                  // onChangeText={(data: any) => field.onChange(convertToModel(data))}
                  onValueChange={(data: any) => field.onChange(convertToModel(data))}
                  selectedValue={convertToUI(field.value)}
                  defaultIsChecked={convertToUI(field.value)}
                  isInvalid={invalid}
                  ref={ref}
                  {...(rest as P)}
                />
              </GuardDebug>
            )}
            name={name}
            rules={validationRules}
            defaultValue={defaultValue}
          />
          <FormControl.ErrorMessage>
            <span className="field-error-message">{error?.message}</span>
          </FormControl.ErrorMessage>
          {props.description && <Small p={1}>{props.description}</Small>}
        </Box>
      </FormControl>
    )
  })

export const Input = ControlledField(NInput, { _light: { bg: 'base.50' }, _dark: { bg: 'base.900' } })
export const TextArea = ControlledField(NTextArea, { _light: { bg: 'base.50' }, _dark: { bg: 'base.900' } })

const SelectTemp = ControlledField(NSelect, {
  _light: { bg: 'base.50' },
  _dark: { bg: 'base.900' },
  dropdownIcon: <ChevronDownIcon size={3} mr={2} />,
}) as any
SelectTemp.Item = NSelect.Item
export const Select = SelectTemp as typeof NSelect & ((props: ISelectProps & ControlledFieldProps) => JSX.Element)

export const RadioGroup = ControlledField(NRadio.Group)
export const CheckboxGroup = ControlledField(NCheckbox.Group)
export const ControlledCheckbox = ControlledField(NCheckbox)

export const Color = ControlledField(
  ({ onValueChange, selectedValue, value, onChange, isInvalid, ...props }: ControlledFieldProps) => {
    const [localValue, setLocalValue] = useState(value)
    const lastValue = useDebounce(localValue, 30)

    useEffect(() => {
      onChange(lastValue)
    }, [lastValue])

    useEffect(() => {
      setLocalValue(value)
    }, [value])

    const _onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setLocalValue(ev.target.value)
    }

    return <input type="color" className="input-color" value={localValue} onChange={_onChange} {...props} />
  }
)
export const SelectAutocomplete = ControlledField(AsyncSelect, { isMulti: false, cacheOptions: true })
export const InputAutocomplete = ControlledField(AsyncCreatableSelect, {
  isMulti: false,
  cacheOptions: true,
  defaultOptions: true,
})
